import React from 'react'
import {Trans, useTranslation, Link} from 'gatsby-plugin-react-i18next'

import Layout from '../components/layout'
import Head from '../components/head'

const NotFound = () => {
  const {t} = useTranslation()
  return (
    <Layout>
      <Head title={t('Page Not Found')} />
      <h1 class="section-title"><Trans>Page Not Found</Trans></h1>
      <p class="notFound"><Link to="/"><Trans>Back To Home</Trans></Link></p>
    </Layout>
  )
}

export default NotFound

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;